<template>
  <div class="main-content">
    <div class="header bg-white border-bottom">
      <div class="container-fluid">
        <div class="header-body border-0">
          <div class="row align-items-end">
            <div class="col">
              <h1 class="header-title">Transaction Details</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-8">
          <button class="btn btn-light mb-4 btn-sm" @click="$router.go('-1')">
            Go back
          </button>
          <div class="card">
            <div class="card-header">
              <h4 class="card-header-title">Transaction Information</h4>
            </div>
            <div class="card-body">
              <h5 v-if="!transaction" class="text-small text-center">
                No Transaction Deatail available
              </h5>
              <div v-if="loadingTransaction" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
              </div>
              <template v-else-if="!loadingTransaction && transaction">
                <div class="row g-0 border-top border-bottom">
                  <div class="col-3 py-4 text-center">
                    <h6 class="text-uppercase text-muted">Amount</h6>
                    <h2 class="mb-0" :class="transaction.type === 'debit'
                      ? 'text-danger'
                      : 'text-success'
                      ">
                      ₦{{ transaction?.amount | money }}
                    </h2>
                  </div>
                  <div class="col-3 py-4 text-center border-start">
                    <h6 class="text-uppercase text-muted">Type</h6>
                    <h2 class="mb-0" :class="transaction.type === 'debit'
                      ? 'text-danger'
                      : 'text-success'
                      ">
                      {{ transaction?.type | titleCase }}
                    </h2>
                  </div>
                  <div class="col-3 py-4 text-center border-start">
                    <h6 class="text-uppercase text-muted">Balance Before</h6>
                    <h2 class="mb-0">
                      ₦{{ transaction?.balance_before | money }}
                    </h2>
                  </div>
                  <div class="col-3 py-4 text-center border-start">
                    <h6 class="text-uppercase text-muted">Balance After</h6>
                    <h2 class="mb-0">
                      ₦{{ transaction?.balance_after | money }}
                    </h2>
                  </div>
                </div>
                <div class="list-group list-group-flush mb-4">
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>User</small>
                      </div>

                      <div class="col-auto">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <a href="#" class="avatar avatar-sm">
                              <img v-if="transaction?.user?.avatar" :src="transaction?.user?.avatar" alt="User Profile"
                                class="avatar-img rounded-circle" />
                              <span class="avatar-title rounded-circle" v-else>
                                {{ `${user ?? 'N/A'}` | initials }}
                              </span>
                            </a>
                          </div>
                          <div class="col ms-n2">
                            <h4 class="mb-1">
                              <a href="#">{{ `${user ?? 'N/A'}` }}</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Reference</small>
                      </div>
                      <div class="col-auto">
                        <small class="">{{ transaction?.reference }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Date</small>
                      </div>
                      <div class="col-auto">
                        <small class="">{{
                          transaction?.created_at | date
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Payment Source</small>
                      </div>
                      <div class="col-auto">
                        <a class="small">{{ transaction?.payment_source }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Description</small>
                      </div>
                      <div class="col-auto">
                        <small class="">{{
                          transaction?.title ?? 'N/A'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Action performed by</small>
                      </div>
                      <div class="col-auto">
                        <small class="">{{ actionInitiator }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Date</small>
                      </div>
                      <div class="col-auto">
                        <small class="">{{ actionDate ?? 'N/A' }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <small>Time</small>
                      </div>
                      <div class="col-auto">
                        <small class="">{{ actionTime ?? 'N/A' }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment'
import { defineProps, computed, ref } from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'

const props = defineProps({
  transactionId: [String, Number]
})

const loadingTransaction = ref(false)
const errorLoadingTransaction = ref(false)
const transaction = ref({})
const auditTrail = ref({})

const fetchTransaction = async () => {
  loadingTransaction.value = true
  errorLoadingTransaction.value = false

  axios.get(`/v1/transactions/${props.transactionId}`)
    .then((res) => {
      transaction.value = res.data
      axios.get(
        `/audit/v1/audits?operationType=wallet-transaction&sort=eventDate:asc&entityId=${props.transactionId}`
      ).then((res) =>
      {
        auditTrail.value = res.data[0]
      })
    })
    .catch(() => {
      errorLoadingTransaction.value = true
    })
    .finally(() => (loadingTransaction.value = false))
}

const transactionDate = computed(() => {
  return moment(transaction.value.eventDate).format('YYYY-MM-DD')
})


const transactionTime = computed(() => {
  return moment(transaction.value.eventDate).format('h:mma')
})

const user = computed(() => {
  return transaction.value?.user?.fname + ' ' + transaction.value?.user?.lname
})

const actionInitiator = computed(() => {
  return auditTrail.value
    ? `${auditTrail.value?.fname + ' ' + auditTrail.value?.lname}`
    : 'N/A'
})
const actionDate = computed(() => {
  return moment(auditTrail?.value?.created_at).format('YYYY-MM-DD')
})

const actionTime = computed(() => { return moment(auditTrail?.value?.created_at).format('h:mma') })

fetchTransaction()
</script>
